// @ts-nocheck
import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    margin: auto;
    max-width: 1440px;
`;

export const WhatsAppButton = styled.a`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 20px;
  right: 20px;
  width: 4rem;
  height: 4rem;
  padding: 10px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
  text-decoration: none;
  transition: all 150ms ease-in-out;
  z-index: 2;
  :hover {
    background-color: #03b244;
    transition: all 150ms ease-in-out;
  }
  
 box-shadow: 2px 10px 65px 0px rgba(0,0,0,1);
-webkit-box-shadow: 2px 10px 65px 0px rgba(0,0,0,1);
-moz-box-shadow: 2px 10px 65px 0px rgba(0,0,0,1);
	font-size: 20px;
  text-decoration: none;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.2);
  :hover {
    background-color: #03b244;
    transition: all 150ms ease-in-out;
  }
`;

export const EnterInContact = styled.a`
  font-weight: 600;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #25d366;
  color: #fff;
  padding: 15px;
  border-radius: 30px;
  text-align: center;
  font-size: 20px;
  text-decoration: none;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.2);
`;


export const LogoImg = styled.img`
  /* max-width: 840px; */
  width: 260px;
  max-height: 170px;
  margin-top: 10px;
`;

export const CarouselContainer = styled.section`
  margin-top: 30px;
  margin-bottom: 40px;
  /* max-width: 80%; */
    @media (max-width: 400px) {
      margin-bottom: 20px;
  }
`;

export const StyledImage = styled.img`
  border-radius: 1%;
  height: auto;
  object-fit: fill;
  width: 100%;
  max-width: 100%;
  max-height: 800px;

`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
`;

export const Title = styled.h1`
  color: #fff;
  text-align: center;
  margin: 0 20px 35px 10px;
`;




export const Footer = styled.footer`
  height: 300px;
  background-color: #581638;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 780px) {
    flex-direction: column;
  }
  @media (max-width: 380px) {
    flex-direction: column;
    height: 350px;
  }
`;

export const ContactAndLocationButton = styled.a`

  text-decoration: none;
  font: inherit;
  height: 90px;
  width: 330px;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: center;
  align-items: flex-start;
  /* background-color: #50fb46; */
  /* margin-top: -80px; */
  transition: all ease-in-out 150ms;
  &:hover {
    background-color: #2b0b1c; /* Define a cor de fundo quando o mouse está sobre o elemento */
    transition: all ease-in-out 150ms;
  }

  @media (max-width: 780px) {
    margin-top: -80px;
  }
    @media (max-width: 330px) {
    /* flex-direction: column; */
    width:250px;
  }

`;



