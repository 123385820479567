import {
  ColumnServicesWrapper,
  ListOfServices,
  MajorServiceName,
  ServicesImg,
  ServiceListArea,
  TextWithExplanationOfService,
  WrapperOfIconAndService,
  DoneIconWrapper,
} from "./styles";
import { MdDone } from "react-icons/md";

interface IServicesOnTheLeftSideProps {
  services: {
    majorserviceName: string;
    explanationOfService: string;
    allServices: string[];
    imagesOfSalonServices: string[];
  };
}


export function ServicesOnTheLeftSideOfPage(
  { services }: IServicesOnTheLeftSideProps) {
  return (

    <ServiceListArea>

      <ColumnServicesWrapper>

        <MajorServiceName>{services.majorserviceName}</MajorServiceName>
        <TextWithExplanationOfService>{services.explanationOfService}
        </TextWithExplanationOfService>


        <ListOfServices>
          {
          services.allServices.map((service, index) => (
            <WrapperOfIconAndService key={index} >
              <DoneIconWrapper>
                <MdDone style={{ width: '15px', height: '15px', color: '#fff' }} />
              </DoneIconWrapper>
              <li key={index}>{service}</li>
            </WrapperOfIconAndService>
          ))
        }

        </ListOfServices>

      </ColumnServicesWrapper>
       {
      services.imagesOfSalonServices.map((image, index) => (
          <div key={index} >
            <ServicesImg src={image}  />
          </div>
        ))
      } 
    </ServiceListArea>
  );
}

