import styled from 'styled-components'

interface ServicesImgProps {
  width?: string;
}


export const Container = styled.section`
    display: flex;
    flex-direction: column;
    margin: 40px auto;
    background-color: #581638;
    width: 100%;
    padding: 30px;
    align-items: center;

`;

export const Title = styled.h1`
  /* margin-right: 100px; */
  align-self: center;
  font-size: 20px;
  font-weight: 200;
  color: #d6d6d6;
  margin-top: 10px;
  font-style: italic;
  
  @media (min-width: 430px) {
    /* align-self: flex-start; */
    
  }
  `;

export const ServiceListArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* align-items: flex-end; */
  @media (min-width: 900px) {
    /* align-self: flex-start; */
      flex-direction: row;
  }
`;
export const ColumnServicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 900px) {
   order: 2; /* Defina a ordem do ColumnServicesWrapper */
 
  }
`
export const MajorServiceName = styled.h4`
  margin-top: 20px;
  font-size: 39px;
  margin-bottom: 15px;
  align-self: flex-end;
  /* width: ; */
`;
export const TextWithExplanationOfService = styled.span`
  min-width: 300px;
  text-align: justify;
  align-self: flex-end;
  
`;
export const ListOfServices = styled.ul`
  margin-top: 15px;
`;
export const WrapperOfIconAndService = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DoneIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  background: rgb(187, 116, 177);
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  padding: 5px;
  margin-right: 35px;
`;


export const ServicesImg = styled.img<ServicesImgProps>`
  height: auto;
  object-fit: fill;
  /* max-width: 100%; */
  max-height: 800px;
  max-width: ${({ width }) => width || '90%'};
  margin-bottom: 20px;
  border-radius: 4px;
  
  @media (min-width: 900px) {
     order: 1; 
    /* align-self: flex-start; */
    margin-top: 90px;
    height: 50vw;
    max-height: 600px;
    width:30vw;
    max-width: 500px;
    /* width:auto; */
    /* max-width: 30%; */
    /* max-height: auto; */
    margin-right:20px;
    margin-left:10px;
    /* min-width: 20vw; */
  }

`;

