import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore from 'swiper';
import { Pagination } from 'swiper/modules';

import testemonial1 from "../../assets/testemonial1.jpg"
import testemonial2 from "../../assets/testemonial2.jpg"
import testemonial4 from "../../assets/testemonial4.jpg"
import testemonial5 from "../../assets/avaliation.png"
import testemonial7 from "../../assets/testemonial7.jpg"

SwiperCore.use([Pagination]);

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
  max-width: 1440px;
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px; 
  @media (min-width: 800px) {
    /* align-self: flex-start; */
    margin-bottom: 60px;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
    max-height: 350px;
    object-fit: fill;
  }
`;

export function CarrouselOfTestimonials() {
  const testimonialImages = [
    testemonial1,
    testemonial4,
    testemonial7,
    testemonial2,
    testemonial5
  ];

  return (
    <>
        <h4 style={{  fontSize: "25px", marginBottom:"15px", marginTop:"40px", }} >Depoimentos
      </h4>
      <h1 style={{marginBottom:"20px", textAlign:"center"}} >O que falam sobre a gente</h1>
      
      
      <div style={{
        maxWidth: "90vw", }} >
      
        <StyledSwiper
        slidesPerView={2}
          
            spaceBetween={10}
              pagination={{ clickable: true , enabled:true}}
        className="mySwiper"
        breakpoints={{
          '@0.00': {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          '@0.75': {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          '@1.00': {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          '@1.50': {
            slidesPerView: 3,
          },
        }}

      >
        {testimonialImages.map((testemonial, index) => (
          <StyledSwiperSlide key={index} >
            <img  src={testemonial} style={{objectFit:"fill"}} alt='Depoimento de cliente' />
          </StyledSwiperSlide>
        ))}
        </StyledSwiper>
      </div>
    </>
    );
};


