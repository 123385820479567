import styled from 'styled-components'


export const Container = styled.section`
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin: 0 auto;
    background-color: #581638;
    width: 100%;
    padding: 30px;
    align-items: center;

`;

export const Title = styled.h1`
  /* margin-right: 100px; */
  align-self: center;
  font-size: 20px;
  font-weight: 200;
  color: #d6d6d6;
  margin-top: 10px;
  font-style: italic;
  
  @media (min-width: 430px) {
    /* align-self: flex-start; */
    
  }
  `;
