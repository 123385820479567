// @ts-nocheck
import { ThemeProvider } from 'styled-components';
import { LandPage } from './components/LandPage';
import { GlobalStyle } from './styles/global';
import { themes } from './theme';

export function App() {
  // 
  return (
    <ThemeProvider theme={themes['RoseTheme']}>
      <title>Salão Afloris Beauty</title>
      <GlobalStyle />
      <LandPage></LandPage>
    </ThemeProvider>
  )
}

export default App
