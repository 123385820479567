import {
  CarouselContainer,
  WhatsAppButton,
  ContactAndLocationButton,
  Container,
  Footer,
  LogoImg,
  StyledImage,
  Title,
  TitleContainer,
  EnterInContact
} from "./styles";


import { IoLogoWhatsapp } from "@react-icons/all-files/io/IoLogoWhatsapp";
import { TiLocationOutline } from "@react-icons/all-files/ti/TiLocationOutline";
import Logo from '../../assets/Logo.png';
import { FaWhatsapp } from 'react-icons/fa';
import { CarrouselOfTestimonials } from "../CarrouselOfTestimonials";
import { SalonServices } from "../SalonServices";



export function LandPage() {

  const WhatsAppIcon = () => {

    return (
      <WhatsAppButton href="https://wa.me/559832347948?text=Olá%2C%20vim%20pelo%20site%20e%20gostaria%20de%20agendar%20um%20horário%20no%20salão%20de%20beleza."
        target="_blank"
        rel="noreferrer"
      >
        <FaWhatsapp
          style={{
            display: "block",
            width: '40px',
            height: '40px',
            zIndex: 2

          }} />

      </WhatsAppButton>
    );
  };
  return (
    <Container>
      <WhatsAppIcon />
      <EnterInContact style={{zIndex:2}}
        href="https://wa.me/559832347948?text=Olá%2C%20vim%20pelo%20site%20e%20gostaria%20de%20agendar%20um%20horário%20no%20salão%20de%20beleza."
        target="_blank"
        rel="noreferrer"

      >AGENDE UM HORÁRIO</EnterInContact>
      <LogoImg src={Logo}
      ></LogoImg>
      <CarouselContainer>

        <StyledImage
          src={require('../../assets/FrenteAfloris.jpg')}

        // src={images[0].src} alt={images[0].alt}
        />
      </CarouselContainer>



      <TitleContainer>
        <Title>MOSTRE ESSA BELEZA PARA O MUNDO</Title>
      </TitleContainer>

      {/* <video width="100%" height="auto" controls autoPlay >
        <source src={"assets/VideoAfloris.mp4"} type="video/mp4" />
        Seu navegador não suporta o elemento de vídeo.
      </video>
      <h1>
        ESPECIALIDADES

      </h1> */}


      <SalonServices></SalonServices>

      
      {/* <h1 style={{marginTop:"50px", fontSize:"50px"}} >“Um salão para chamar de meu!”
      </h1> */}
      {/* <NewsOnInstagram/> */}
      <CarrouselOfTestimonials/>
      
      {/* <video width="100%" height="auto" controls autoPlay >
        <source src={"assets/VideoAfloris.mp4"} type="video/mp4" />
        Seu navegador não suporta o elemento de vídeo.
      </video>
      <h1>
        ESPECIALIDADES

      </h1> */}



      <Footer>

        <ContactAndLocationButton href="https://wa.me/559832347948?text=Olá%2C%20vim%20pelo%20site%20e%20gostaria%20de%20agendar%20um%20horário%20no%20salão%20de%20beleza"
          target="_blank"
          rel="noreferrer" >
          <h4
            style={{
              fontSize: '15px', textDecoration: 'none', fontWeight: 'bold',
              // textAlign: 'justify',
              maxWidth: '280px'
            }}

          >
            <IoLogoWhatsapp
              style={{
                marginRight: '5px',
                width: '20px',
                height: '20px',
                marginBottom: '-3px',
                zIndex:2
                // color: '#25d366'
              }}
            />
            WhatsApp Afloris   (98)3234-7948
            Agendamentos, dúvidas e informações
          </h4>
          {/* <br></br> */}

        </ContactAndLocationButton>


        {/* address */}
        <ContactAndLocationButton href="https://maps.app.goo.gl/i1TAxTcpQE5dMhp5A"
          target="_blank"
          rel="noreferrer" >

          <h4
            style={{ fontSize: '15px', textDecoration: 'none', textAlign: 'justify', maxWidth: '285px' }}
          >
            <TiLocationOutline style={{ width: '20px', height: '20px', marginBottom: '-3px' }}
            // color={'grey'}
            />

            Afloris Beauty Lounge - Av. Dois, N°18 - COHAB Anil I, São Luís - MA, 65050-140</h4>
        </ContactAndLocationButton>

      </Footer>

    </Container >
  );
}

