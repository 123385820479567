import blondeHair from '../assets/blondeHair.jpg';
import redHair from '../assets/redHair.jpg'
import aesthetic1 from '../assets/aesthetic1.png'
import aesthetic2 from '../assets/LipoDePapada.jpg'
import extensaoCilios from '../assets/extensaoCilios.jpg'
import HydraGloss from '../assets/HydraGlossLips.jpg'


export const services = {
    hair: {
        majorserviceName: 'Cabelo',
        allServices: [
            "Coloração",
            "Selagem",
            "Botox & Detox Capilar",
            "Mechas",
            "Tratamento",
            "Cortes"
        ],
        explanationOfService: 'Contamos com especialistas dedicados a proporcionar cortes, colorações e tratamentos capilares que se alinham perfeitamente ao seu estilo. Seja para uma mudança sutil ou uma transformação completa, estamos aqui para atender às suas necessidades e superar suas expectativas',
        imagesOfSalonServices: [blondeHair, redHair]
    },
    aesthetic: {
        majorserviceName: 'Estética',
        allServices: [
            "Glúteo Max",
            "Lipo de Papada",
            "Estética Corporal e Facial",
            "Massagem Modeladora",
            "Aplicação de Enzimas",
            "Massoterapia",
            "Peeling",
        ],
        explanationOfService: 'Descubra o poder da estética corporal em nosso salão de beleza. Oferecemos uma variedade de tratamentos projetados para realçar a beleza do seu corpo. Nossa equipe está comprometida em proporcionar soluções personalizadas que atendam às suas necessidades individuais. Agende uma consulta conosco e embarque em uma jornada de cuidados dedicados à sua estética. Estamos aqui para ajudar você a alcançar seus objetivos e se sentir ainda mais confiante em sua própria pele.',
        imagesOfSalonServices: [aesthetic1, aesthetic2]
    },
    
    otherServices: {
        majorserviceName: 'Outros serviços',
        allServices: [
            "Extensão de Cilios",
            "Hydra Gloss Lips",
            "Micropigmentação de Sobrancelhas",
            "Micropigmentação Labial",
            "Design de Sobrancelhas com Henna",
        ],
        explanationOfService: '',
        imagesOfSalonServices: [extensaoCilios, HydraGloss]
    }
};
