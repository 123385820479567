import { services } from "../../utils/salonServices";
import { ServicesOnTheLeftSideOfPage } from "../ServicesOnTheLeftSideOfPage";
import { ServicesOnTheRightSideOfPage } from "../ServicesOnTheRightSideOfPage";
import {
  Container,
  Title,
} from "./styles";
// TODO: put estetica at right side, and manicure and pedicure at left side



export function SalonServices() {



  return (
    <Container>
      <Title>
        Nossos serviços
      </Title>
      <ServicesOnTheLeftSideOfPage
        services={services.hair}
      />
      <ServicesOnTheRightSideOfPage
        services={services.aesthetic}
      />
      <ServicesOnTheLeftSideOfPage
        services={services.otherServices} />
    </Container >
  );
}

